<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-button
        style="float: right"
        type="primary"
        @click="dialogFormVisible = true"
        >添加菜单</el-button
      >
    </div>
    <el-dialog title="添加菜单" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off" placeholder="请输入左侧菜单名称"></el-input>
        </el-form-item>
        <el-form-item label="路径别名" :label-width="formLabelWidth">
          <el-input v-model="form.linkname" autocomplete="off" placeholder="请输入左侧菜单别名：注意唯一"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标" :label-width="formLabelWidth">
          <el-input v-model="form.icon" autocomplete="off" placeholder="请输入左侧菜单图标名称"></el-input>
        </el-form-item>
        <el-form-item label="菜单路径" :label-width="formLabelWidth">
          <el-input v-model="form.path" autocomplete="off" placeholder="请输入左侧菜单路径：注意唯一"></el-input>
        </el-form-item>
        <el-form-item label="是否显示菜单" :label-width="formLabelWidth">
          <el-select v-model="form.isshow" placeholder="请选择">
            <el-option
              v-for="item in showoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单描述" :label-width="formLabelWidth">
          <el-input v-model="form.des" autocomplete="off" placeholder="请输入左侧菜单描述"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限" :label-width="formLabelWidth">
          <el-select v-model="form.roleid" multiple placeholder="请选择该菜单的权限">
            <el-option
              v-for="item in options"
              :key="item._id"
              :label="item.name"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级菜单" :label-width="formLabelWidth">
          <el-select v-model="form.parent" clearable placeholder="为空时为主菜单">
            <el-option v-for="item in parents"
            :key="item._id"
            :label="item.name"
            :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addrouter()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--修改-->
    <el-dialog title="修改菜单" :visible.sync="editdialogFormVisible">
      <el-form :model="editform">
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="editform.name" autocomplete="off" placeholder="请输入左侧菜单名称"></el-input>
        </el-form-item>
        <el-form-item label="路径别名" :label-width="formLabelWidth">
          <el-input v-model="editform.linkname" autocomplete="off" placeholder="请输入左侧菜单别名：注意唯一"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标" :label-width="formLabelWidth">
          <el-input v-model="editform.icon" autocomplete="off" placeholder="请输入左侧菜单图标名称"></el-input>
        </el-form-item>
        <el-form-item label="菜单路径" :label-width="formLabelWidth">
          <el-input v-model="editform.path" autocomplete="off" placeholder="请输入左侧菜单路径：注意唯一"></el-input>
        </el-form-item>
        <el-form-item label="是否显示菜单" :label-width="formLabelWidth">
          <el-select v-model="editform.isshow" placeholder="请选择">
            <el-option
              v-for="item in showoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单描述" :label-width="formLabelWidth">
          <el-input v-model="editform.des" autocomplete="off" placeholder="请输入左侧菜单描述"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限" :label-width="formLabelWidth">
          <el-select v-model="editform.roleid" multiple placeholder="请选择该菜单的权限">
            <el-option
              v-for="item in options"
              :key="item._id"
              :label="item.name"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="editform.parent" label="上级菜单" :label-width="formLabelWidth">
          <el-select v-model="editform.parent" clearable placeholder="为空时为主菜单">
            <el-option v-for="item in parents"
            :key="item._id"
            :label="item.name"
            :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editonerouter(editform)"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--修改-->
    <el-table
      :data="items"
      style="width: 100%; margin-bottom: 20px"
      row-key="_id"
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="name" label="名称" width="180"> </el-table-column>
      <el-table-column prop="icon" label="图标" width="100">
        <template slot-scope="scope">
          <feather-icon :icon="scope.row.icon" />
        </template>
      </el-table-column>
      <el-table-column prop="path" label="路径" width="180"> </el-table-column>
      <el-table-column prop="linkname" label="路径别名" width="180"></el-table-column>
      <el-table-column prop="isshow" label="左侧是否显示" width="180">
        <template slot-scope="scope">
          <el-switch
          disabled
            v-model="scope.row.isshow"
          ></el-switch>
        </template></el-table-column>
      <el-table-column prop="des" label="描述" width="180"> </el-table-column>
      <el-table-column prop="meta" label="权限" width="260">
        <template slot-scope="scope">
                <span v-for="item in scope.row.meta[0].roleid">《{{ item.name }}》</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleedit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </el-card>
</template>
<script>

export default {
  data() {
    return {
      editdialogFormVisible: false,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页数据量
      total: 0, // 总数据量
      items: [],
      parents: [],
      form: {
        name: "",
        linkname: "",
        icon: "",
        path: "",
        des: "",
        isshow: false,
        roleid:[],
        parent: "",
      },
      editform: {
        _id:"",
        name: "",
        linkname: "",
        icon: "",
        path: "",
        des: "",
        isshow: false,
        roleid:[],
        parent: "",
      },
      options: [],
      checkoptions: [],
      tableData: [],
      showoptions: [{
        value: true,
        label: '显示'
      }, {
        value: false,
        label: '不显示'
      }],
    };
  },
  methods: {
    editonerouter(data){
      this.$api.menus.geteditonerouter(data).then(res =>{
        this.$message({
          message:res.data.msg,
          type: 'success'
        });
        this.loadItems()
        this.editdialogFormVisible = false;
      })
    },
    handleedit(data){
      this.editdialogFormVisible = true;
      const {_id,name,linkname,icon,path,des,parent,isshow} = data
      const meta = data.meta
      this.editform._id = _id
      this.editform.name = name
      this.editform.linkname = linkname
      this.editform.isshow = isshow
      this.editform.icon= icon
      this.editform.path= path
      this.editform.des= des
      this.editform.roleid= meta[0].roleid.map(item =>(item._id))
      this.editform.parent= parent
    },
    handleDelete(data){
      this.$api.menus.getdeleterouter(data).then(res =>{
        this.$message({
          message:res.data.msg,
          type: 'success'
        });
        this.loadItems()
      })
    },
    addrouter() {
      const data = {
        name: this.form.name,
        linkname: this.form.linkname,
        isshow:this.form.isshow,
        icon: this.form.icon,
        path: this.form.path,
        des: this.form.des,
        roleid: this.form.roleid,
        parent: this.form.parent,
      }
      //console.log(this.form.parent)
      this.$api.menus.addnewrouter(data).then(res =>{
        this.$message({
          message:res.data.msg,
          type: 'success'
        });
        this.getmenu()
        this.dialogFormVisible = false
        this.form = {}
      }).catch(error => {
        this.$message({
          message:res.data.msg,
          type: 'warning'
        });
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadItems();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.loadItems();
    },
    loadItems(){
      const data = {
        currentPage: this.currentPage,
        pageSize:this.pageSize
      }
      this.$api.menus.getpagination(data).then(res =>{
        this.currentPage = res.data.pagination.currentPage;
        this.pageSize = res.data.pagination.pageSize;
        this.total = res.data.pagination.total;
        this.items = res.data.data;
      })
    },
    getmenu(){
        this.$api.menus.getrouterlist().then(res =>{
            this.parents = res.data.data
        })
    },
    getroleid(){
        this.$api.roleids.getroleidlist().then(res =>{
            this.options = res.data.data
        })
    }
  },
  created(){
    this.getmenu()
    this.getroleid()
    this.loadItems()
  }
};
</script>
<style>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
</style>
